import { css } from 'styled-components';

export * from '../updates/common.css';
import { schemes } from '../../../tabula.config';

export const section = css`
  background-color: var(--brand-color);
`;

export const eyebrow = css`
  color: white !important;
  text-align: center;
`;

export const logoBlock = css`
  --logo-height: 20px;
  @media (min-width: 24em) {
    --logo-height: 24px;
  }
  @media (min-width: 36em) {
    --logo-height: 28px;
  }
  @media (min-width: 48em) {
    --logo-height: 32px;
  }
  @media (min-width: 60em) {
    --logo-height: 36px;
  }
`;
