import React from 'react';

import { Link } from 'src/containers/Link';
import { FluidImg } from 'src/embeds/image/image-dato';
import { Flow, Stack } from 'src/system2';
import * as css from './drawer-logo-chain.css';

function hexToRgba(hex, alpha = 1) {
  hex = hex.replace(/^#/, '');
  if (!~[3, 4, 6, 8].indexOf(hex.length)) throw 'incorrect hex value length';
  if (hex.length <= 4) {
    hex = hex.split('').reduce((str, x) => str + x + x, '');
  }
  const bigInt = parseInt(hex, 16);
  const [r, g, b, a] = [bigInt & 255, (bigInt >> 8) & 255, (bigInt >> 16) & 255, (bigInt >> 24) & 255]
    .slice(0, hex.length / 2)
    .reverse();
  return [r, g, b, a];
}

function rgbDuoToneMatrix(rgbMin, rgbMax) {
  var value = [];
  value = value.concat([rgbMax[0] / 256 - rgbMin[0] / 256, 0, 0, 0, rgbMin[0] / 256]);
  value = value.concat([rgbMax[1] / 256 - rgbMin[1] / 256, 0, 0, 0, rgbMin[1] / 256]);
  value = value.concat([rgbMax[2] / 256 - rgbMin[2] / 256, 0, 0, 0, rgbMin[2] / 256]);
  value = value.concat([0, 0, 0, 1, 0]);
  return value;
}

function DuoTone({
  colors: [hexMin = '#ff0ff0', hexMax = '#000000'] = [],
  id = 'duotone',
  children,
  ...props
} = {}) {
  var rgbMax = hexToRgba(hexMax);
  var rgbMin = hexToRgba(hexMin);
  const matrix = rgbDuoToneMatrix(rgbMin, rgbMax);

  return (
    <div {...props}>
      <svg style={{ position: 'absolute' }} visibility="hidden">
        <filter width="100%" height="100%" x="0" y="0" colorInterpolationFilters="srgb" id={id}>
          <feColorMatrix id="fe" type="matrix" values={matrix}></feColorMatrix>
        </filter>
      </svg>
      {children}
    </div>
  );
}

export default function Drawer({ heading, callouts }) {
  const logos = callouts.filter((callout) => callout.__typename == 'DatoCmsLogoBlock');

  return (
    <section css={[css.section]}>
      <Stack className="reset2" sx={{ pt: 40, pb: { _: 20, m: 32 }, gap: 24 }}>
        <p className="f-mono" css={[css.eyebrow, css.metaHeading]}>
          Top Enterprises Trust Rasa
        </p>
        <DuoTone colors={['#fff', '#fff']} id="logolist">
          <Flow
            as="ul"
            role="list"
            sx={{
              gapX: { _: 40, s: 40, m: 60, l: 80 },
              gapY: { _: 16, m: 32 },
              mx: 'auto',
              jc: 'center',
              px: { _: 20, m: 40, l: 80 },
            }}
            style={{ filter: 'url(#logolist)' }}
          >
            {logos?.map((logo, i) => {
              return (
                <li key={i} role="listitem" css={[css.logoBlock]}>
                  <Link to={logo.url}>
                    <div
                      className="logo-hold"
                      style={{
                        width: `calc(${logo.media.width / logo.media.height} * var(--logo-height))`,
                        height: `var(--logo-height)`,
                      }}
                    >
                      <FluidImg
                        constrain
                        width="100%"
                        data={logo.media}
                        style={{
                          opacity: 0.8,
                        }}
                      />
                    </div>
                  </Link>
                </li>
              );
            })}
          </Flow>
        </DuoTone>
      </Stack>
    </section>
  );
}
